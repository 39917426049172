import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CallbackPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthCallback = async () => {
      // Parse the authorization code from the query string
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');

      if (!code) {
        console.error('Authorization code not found in callback');
        return;
      }

      try {
        // Send the authorization code to the backend for token exchange
        const response = await fetch('/api/v1/bsky/callback', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code }),
          credentials: 'include',
        });

        if (!response.ok) {
          console.error('Failed to exchange authorization code');
          const errorData = await response.json();
          console.error('Error details:', errorData);
          return;
        }

        // Process response from the server (token is stored in a cookie)
        const data = await response.json();
        console.log('Authentication successful:', data);

        // try {
        //     const response = await fetch('/api/v1/bsky/feed', {
        //       method: 'GET',
        //       credentials: 'include',
        //     });
        
        //     if (!response.ok) {
        //       console.error('Failed to fetch feed:', await response.json());
        //       return;
        //     }
        
        //     const feed = await response.json();
        //     console.log('User Feed:', feed);
        //   } catch (error) {
        //     console.error('Error fetching user feed:', error);
        //   }

        // Redirect to the home page after successful authentication
        navigate('/');
      } catch (error) {
        console.error('Error during token exchange:', error);
      }
    };

    handleAuthCallback();
  }, [navigate]);

  return <div>Processing your sign-in...</div>;
};

export default CallbackPage;
