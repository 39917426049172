import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
    color: #fff;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
  }
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #06060e;
  padding: 20px;
`;

const AuthForm = styled.div`
  background-color: #111;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
`;

const FormTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  color: #ffba3b;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: lightgray;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  background-color: #333;
  border: 1px solid #666;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: orange;
  }
`;

const AuthButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: orange;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;

  &:hover {
    background-color: #ffba3b;
  }
`;

const SwitchModeLink = styled.span`
  display: block;
  text-align: center;
  margin-top: 20px;
  color: #ffba3b;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);  // 1: request OTP, 2: enter OTP, 3: reset password
  const [error, setError] = useState('');

  const requestOtp = async () => {
    try {
      await axios.post('/api/v1/request_reset', { email }); // todo: make this also ask for the username
      setStep(2);
    } catch (error) {
      setError('Error requesting OTP. Please check your email and try again.');
    }
  };

  const verifyOtp = async () => {
    try {
      await axios.post('/api/v1/verify_otp', { email, otp });
      setStep(3);
    } catch (error) {
      setError('Invalid OTP. Please try again.');
    }
  };

  const resetPassword = async () => {
    try {
      await axios.post('/api/v1/reset_password', { email, otp, newPassword });
      alert('Password reset successful!');
      window.location.replace('/login');  // Redirect to login page
    } catch (error) {
      setError('Error resetting password. Please try again.');
    }
  };

  return (
    <React.Fragment>
      <GlobalStyle />
      <PageContainer>
        <AuthForm>
          <FormTitle>Forgot Password</FormTitle>
          {error && <ErrorMessage>{error}</ErrorMessage>}

          {step === 1 && (
            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
              <AuthButton onClick={requestOtp}>Request OTP</AuthButton>
            </FormGroup>
          )}

          {step === 2 && (
            <>
              <FormGroup>
                <Label htmlFor="otp">One-Time Password</Label>
                <Input
                  id="otp"
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter the OTP sent to your email"
                  required
                />
              </FormGroup>
              <AuthButton onClick={verifyOtp}>Verify OTP</AuthButton>
            </>
          )}

          {step === 3 && (
            <>
              <FormGroup>
                <Label htmlFor="newPassword">New Password</Label>
                <Input
                  id="newPassword"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter your new password"
                  required
                />
              </FormGroup>
              <AuthButton onClick={resetPassword}>Reset Password</AuthButton>
            </>
          )}

          <SwitchModeLink onClick={() => window.location.replace('/login')}>
            Back to Login
          </SwitchModeLink>
        </AuthForm>
      </PageContainer>
    </React.Fragment>
  );
};

export default PasswordReset;
