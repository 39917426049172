import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const SearchContainer = styled.div`
  width: 30%; /* Adjust as necessary */
  padding: 10px;
  background-color: #06060e;
  z-index: 1000;

  @media (max-width: 768px) {
    display: none;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #4b4d4f;
  color: white;
  font-size: 16px;

  &:focus {
    outline: none;
  }
`;

const SearchButton = styled.button`
  position: absolute;
  top: 50%;
  right: 5px; /* Align button with padding inside input */
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  padding: 5px;
  background-color: orange;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #ffba3b;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchQuery.trim()) {
      navigate(`/search?query=${searchQuery}`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <SearchContainer>
      <InputWrapper style={{ display: 'flex', alignItems: 'center' }}>
        <SearchInput
          type="text"
          placeholder="Search for posts or users..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyPress}
          style={{ flex: 1 }}
        />
        <SearchButton onClick={handleSearch}>
          <img src={'https://api.yipyap.io/resource/search.png'} alt="Search" />
        </SearchButton>
      </InputWrapper>
    </SearchContainer>
  );
};

export default SearchBar;
