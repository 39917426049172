import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Navigation from './Navigation';
import MobileNav from './MobileNav';
import SearchBar from './SearchBar';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10px;
  gap: 20px;
`;

const StyledSettings = styled.div`
  width: 40%;
  margin: 0 auto !important;
  border: 1px solid orange;
  padding: 20px;
  background-color: #06060e;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
`;

const SettingsTitle = styled.h2`
  color: #fff;
  text-align: center;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
`;

const DescriptionText = styled.p`
  color: #fff;
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  transition: .4s;
  border: 2px solid orange;
  border-radius: 34px; // make it fully rounded

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%; // make it a circle
  }

  ${ToggleInput}:checked + & {
    background-color: #6e4709;
  }

  ${ToggleInput}:checked + &:before {
    transform: translateX(24px);
  }

  ${ToggleInput}:focus + & {
    box-shadow: 0 0 1px #2196F3;
  }

  ${ToggleInput}:active + & {
    background-color: #000;
  }
`;

const Terms = () => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <PageContainer>
        <Navigation />
        <StyledSettings>
            <SettingsTitle>
                Terms of Service
            </SettingsTitle>
            <DescriptionText>
                Last Updated: December 5, 2024
            </DescriptionText>
            <DescriptionText>
                Welcome to YipYap (the “Platform”). These Terms of Service (“Terms”) govern your use of YipYap and its associated websites, services, and applications operated by YipYap (“we,” “us,” or “our”). By accessing, registering for, or using the Platform, you agree to be bound by these Terms. If you do not agree, please discontinue use immediately.
            </DescriptionText>
            <br />

            <SettingsTitle>
                1. Eligibility and Account Registration
            </SettingsTitle>
            <DescriptionText>
                1.1 Age Requirement: You must be at least 13 years old to use YipYap. If you are under the age of 18 (or the legal age of majority in your jurisdiction), you represent that a parent or legal guardian has reviewed and agreed to these Terms on your behalf.
            </DescriptionText>
            <DescriptionText>
                1.2 Account Registration: To access certain features, you must register for an account. You agree to:
            </DescriptionText>
            <DescriptionText>
                - Provide accurate, complete, and current information.
            </DescriptionText>
            <DescriptionText>
                - Maintain and promptly update your information if it changes.
            </DescriptionText>
            <DescriptionText>
                - Keep your login credentials secure and confidential.
            </DescriptionText>
            <DescriptionText>
                - Accept responsibility for all activities that occur under your account.
            </DescriptionText>
            <DescriptionText>
                1.3 Username Standards: Usernames may only contain letters and numbers. Usernames deemed deceptive, offensive, or that impersonate others may be disallowed at our sole discretion.
            </DescriptionText>
            <br />

            <SettingsTitle>
                2. User Conduct and Content
            </SettingsTitle>
            <DescriptionText>
                2.1 User-Generated Content: YipYap allows you to post text, images, videos, and other content (“Content”). You retain ownership of the Content you create and post. However, by posting Content on the Platform, you grant us a non-exclusive, worldwide, royalty-free license to host, reproduce, modify, distribute, and display your Content solely for the purpose of operating, developing, and improving YipYap.
            </DescriptionText>
            <DescriptionText>
                2.2 Content Standards: You agree that your Content and activity on the Platform will not:
            </DescriptionText>
            <DescriptionText>
                - Contain illegal, hateful, harassing, threatening, defamatory, obscene, or otherwise objectionable material.
            </DescriptionText>
            <DescriptionText>
                - Promote violence, discrimination, or any illegal activities.
            </DescriptionText>
            <DescriptionText>
                - Infringe upon the intellectual property or privacy rights of others.
            </DescriptionText>
            <DescriptionText>
                - Include unauthorized advertising, spam, or malware.
            </DescriptionText>
            <DescriptionText>
                2.3 Media and NSFW Content: YipYap may allow posting media (images or videos). Certain posts may contain mature or NSFW (Not Safe For Work) content. You must comply with all applicable laws and ensure that any NSFW content is appropriately tagged or restricted according to Platform guidelines.
            </DescriptionText>
            <DescriptionText>
                2.4 No Impersonation or Misrepresentation: You may not impersonate other individuals, organizations, brands, or use deceptive tactics to mislead others.
            </DescriptionText>
            <DescriptionText>
                2.5 Approval and Unreviewed Posts: Certain features (like Explore or posts awaiting approval) may be moderated. We reserve the right to approve, disapprove, or remove Content at our sole discretion, without prior notice.
            </DescriptionText>
            <br />

            <SettingsTitle>
                3. Moderation and Enforcement
            </SettingsTitle>
            <DescriptionText>
                3.1 Monitoring of Content: We have no obligation to monitor your Content or use of the Platform. However, we reserve the right to review, remove, or refuse to display any Content at any time for any reason.
            </DescriptionText>
            <DescriptionText>
                3.2 Reporting Violations: Users may report violations of these Terms. We will review reported Content or activity and take appropriate enforcement actions, which may include Content removal, account suspension, or termination.
            </DescriptionText>
            <DescriptionText>
                3.3 Account Suspension or Termination: We may suspend or terminate your account if we believe you have violated these Terms or if your actions pose legal risk or harm to the Platform or other users.
            </DescriptionText>
            <br />

            <SettingsTitle>
                4. Intellectual Property
            </SettingsTitle>
            <DescriptionText>
                4.1 Our Rights: All trademarks, service marks, logos, and other proprietary content used in YipYap are the property of their respective owners. You may not use these marks without prior written permission.
            </DescriptionText>
            <DescriptionText>
                4.2 Your Rights to Your Content: You retain all rights to your Content, subject to the license granted to us to use your Content for providing the Platform’s services.
            </DescriptionText>
            <br />

            <SettingsTitle>
                5. Privacy
            </SettingsTitle>
            <DescriptionText>
                Your privacy matters. Please review our Privacy Policy to understand how we collect, use, and share information about you. By using YipYap, you consent to the collection and use of your information as outlined in our Privacy Policy.
            </DescriptionText>
            <br />

            <SettingsTitle>
                6. Third-Party Links and Services
            </SettingsTitle>
            <DescriptionText>
                YipYap may contain links to third-party websites or services. We are not responsible for the content, accuracy, or practices of any third-party websites or services. Your use of these third parties is at your own risk.
            </DescriptionText>
            <br />

            <SettingsTitle>
                7. Disclaimer of Warranties
            </SettingsTitle>
            <DescriptionText>
                Your use of YipYap is at your sole risk. We provide the Platform and related services “AS IS” and “AS AVAILABLE.” To the fullest extent permitted by law, we disclaim all warranties, express or implied, including warranties of merchantability, fitness for a particular purpose, and non-infringement.
            </DescriptionText>
            <DescriptionText>
                We do not guarantee the reliability, accuracy, or availability of the Platform or that it will meet your requirements or be uninterrupted, timely, secure, or error-free.
            </DescriptionText>
            <br />

            <SettingsTitle>
                8. Limitation of Liability
            </SettingsTitle>
            <DescriptionText>
                To the maximum extent permitted by law, in no event shall we, our affiliates, or our licensors be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the Platform. Our total liability for any claim arising out of or related to these Terms shall not exceed one hundred U.S. dollars (USD $100).
            </DescriptionText>
            <br />

            <SettingsTitle>
                9. Indemnification
            </SettingsTitle>
            <DescriptionText>
                You agree to defend, indemnify, and hold us harmless from any claims, damages, liabilities, losses, or expenses (including legal fees) arising out of or related to your use of YipYap, your Content, or your violation of these Terms.
            </DescriptionText>
            <br />

            <SettingsTitle>
                10. Changes to the Terms
            </SettingsTitle>
            <DescriptionText>
                We may update these Terms from time to time. Changes will be effective when posted. Your continued use of the Platform after any changes constitutes acceptance of the revised Terms.
            </DescriptionText>
            <br />

            <SettingsTitle>
                11. Termination
            </SettingsTitle>
            <DescriptionText>
                You may stop using YipYap at any time. We may terminate or suspend your access to YipYap for any reason, including violations of these Terms. Upon termination, all licenses and rights to use the Platform will cease.
            </DescriptionText>
            <br />

            <SettingsTitle>
                12. Governing Law and Dispute Resolution
            </SettingsTitle>
            <DescriptionText>
                These Terms and any dispute arising out of them will be governed by the laws of Illinois, United States, without regard to its conflict of laws principles. You agree to submit to the personal jurisdiction of the courts located in Illinois, United States for any actions.
            </DescriptionText>
            <br />

            <SettingsTitle>
                13. Miscellaneous
            </SettingsTitle>
            <DescriptionText>
                If any provision of these Terms is deemed invalid or unenforceable, the remaining provisions will remain in full force and effect. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
            </DescriptionText>
            <DescriptionText>
                These Terms constitute the entire agreement between you and us regarding your use of YipYap and supersede any prior agreements.
            </DescriptionText>
            <br />

            <DescriptionText>
                <b>By accessing or using YipYap, you acknowledge that you have read, understood, and agree to be bound by these Terms.</b>
            </DescriptionText>
        </StyledSettings>
        <MobileNav />
        <SearchBar />
      </PageContainer>
    </React.Fragment>
  );
}

export default Terms;
