import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { createGlobalStyle } from 'styled-components';
import Cookies from 'js-cookie';
import MobileNav from './MobileNav';
import Navigation from './Navigation';
import SearchBar from './SearchBar';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding-top: 20px;
  background-color: #06060e;
`;

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 40%;
  background-color: #06060e;
  padding: 0;
  border: 1px solid orange;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const Notification = styled.div`
  position: relative;
  background-color: #06060e;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  color: #FFF;
  border-bottom: 1px solid orange;
`;

const NotificationHeader = styled.div`
  display: flex;
`;

const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const NotificationTitle = styled.h3`
  margin: 0;
`;

const CaughtUpMessage = styled.div`
  color: #aaa;
  text-align: center;
  padding: 20px;
  font-size: 18px;
`;

const MessageText = styled.p`
  color: #dde;
`;

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    
    useEffect(() => {
      const fetchNotifications = async () => {
        if (!Cookies.get('token')) {
          alert("No token");
          return;
        }
        try {
            axios({
                method: 'get',
                url: '/api/v1/notifications',
                headers: {
                  'Authorization': `Bearer ${Cookies.get('token')}`
                }
              })
              .then(res => {
                try {
                    setNotifications(res.data);
                }
                catch(e) { }
                })
          
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      };
  
      fetchNotifications();
    }, []);
  
    return (
      <React.Fragment>
        <GlobalStyle />
        <PageContainer>
        <Navigation />
        <NotificationContainer>
          {notifications.map((notification, index) => (
            <Notification key={notification.id}>
              <NotificationHeader>
                <ProfileImage src={notification.userPFP} alt={notification.username} />
                <NotificationContent>
                  <NotificationTitle>{notification.title}</NotificationTitle>
                  <MessageText>{notification.message}</MessageText>
                </NotificationContent>
              </NotificationHeader>
            </Notification>
          ))}
          <CaughtUpMessage>You're all caught up!</CaughtUpMessage>
          </NotificationContainer>
          <SearchBar />
          <MobileNav />
        </PageContainer>
      </React.Fragment>
    );
  }
  
  export default Notifications;