import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import Navigation from './Navigation';
import MobileNav from './MobileNav';
import SearchBar from './SearchBar';
import { useParams, useLocation } from 'react-router-dom';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;

  @media (max-width: 768px) {
    margin: 0px;
  }
`;

const StyledFeed = styled.div`
  width: 40%;
  margin: 0 auto !important;
  padding: 20px;
  background-color: #06060e;
  border-top: 1px solid orange;

  @media (min-width: 768px) {
    border-left: 1px solid orange;
    border-right: 1px solid orange;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const ProfilePicture = styled.img`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  object-fit: cover;
  overflow-clip-margin: unset;
  object-position: center;
`;

const UserInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 6px;
`;

const DisplayName = styled.a`
  font-weight: bold;
  color: white;
  margin-bottom: 0px;
  text-decoration: none;
`;

const Username = styled.span`
  color: gray;
  margin-bottom: 0px;
`;

const Bio = styled.p`
  color: white;
  margin: 0;
`;

const FollowButton = styled.button`
  background-color: ${({ isFollowing }) => (isFollowing ? 'orange' : 'transparent')};
  color: ${({ isFollowing }) => (isFollowing ? 'white' : 'orange')};
  border: 1px solid orange;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ isFollowing }) => (isFollowing ? 'red' : 'orange')};
    color: white;
  }
`;

const UserList = ({ users }) => {
  const [followStatus, setFollowStatus] = useState({});

  useEffect(() => {
    const initialFollowStatus = {};
    users.forEach((user) => {
      initialFollowStatus[user.username] = user.iFollow;
    });
    setFollowStatus(initialFollowStatus);
  }, [users]);

  const handleFollowToggle = async (username) => {
    setFollowStatus((prevState) => ({
      ...prevState,
      [username]: !prevState[username],
    }));

    try {
      await axios.post(
        '/api/v1/make_follow',
        { username },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        }
      );
    } catch (error) {
      console.error('Error toggling follow:', error);
    }
  };

  return (
    <React.Fragment>
      {users.map((user) => (
        <UserContainer key={user.username}>
          <ProfilePicture src={user.profilePictureURL} alt={user.username} />
          <UserInfo>
            <DisplayName href={`/${user.username}`}>{user.displayName}</DisplayName>
            <Username>@{user.username}</Username>
            <Bio>{user.bio}</Bio>
          </UserInfo>
          <FollowButton
            isFollowing={followStatus[user.username]}
            onClick={() => handleFollowToggle(user.username)}
          >
            {followStatus[user.username] ? 'Following' : 'Follow'}
          </FollowButton>
        </UserContainer>
      ))}
    </React.Fragment>
  );
};

const UserListPage = () => {
  const [users, setUsers] = useState([]);
  const { postId, Username } = useParams();
  const location = useLocation();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let endpoint;

        if (location.pathname.endsWith('/likes')) {
          endpoint = `/api/v1/users?PostID=${postId}`;
        } else if (location.pathname.endsWith('/howls')){
          endpoint = `/api/v1/users?Howls=${postId}`;
        } else if (location.pathname.endsWith('/followers')) {
          endpoint = `/api/v1/users?Follows=${Username}`;
        } else if (location.pathname.endsWith('/following')) {
          endpoint = `/api/v1/users?IFollow=${Username}`;
        } else {
          console.error('Unrecognized URL pattern');
          return;
        }

        const response = await axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
          },
        });

        setUsers(response.data.users || []);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [postId, location.pathname, Username]);

  return (
    <React.Fragment>
      <GlobalStyle />
      <Layout>
        <Navigation />
        <StyledFeed>
          <UserList users={users} />
        </StyledFeed>
        <SearchBar />
      </Layout>
      <MobileNav />
    </React.Fragment>
  );
};

export default UserListPage;
