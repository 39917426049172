import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import PostList from './PostList';
import MobileNav from './MobileNav';
import Navigation from './Navigation';
import SearchBar from './SearchBar';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const StyledFeed = styled.div`
  width: 40%;
  margin: 0 auto !important;
  border: 1px solid orange;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  max-width: calc(100% - 42px); // I don't know why this is necessary, but it is.
  background-color: #06060e;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10px;
  gap: 20px;
`;

const SearchPage = () => {
  const [data, setData] = useState({ posts: [], users: {} });
  const [continuesFrom, setContinuesFrom] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [AllowNSFW, setAllowNSFW] = useState(false);
  const [searchParams] = useSearchParams();

  const fetchPosts = useCallback(async () => {
    if (loading || !hasMore) return;

    const token = Cookies.get('token');
    let myID = -1;

    if (token) {
      try {
        const { data: validateRes } = await axios.get('/api/v1/validate', {
          headers: { Authorization: `Bearer ${token}` },
        });
        myID = validateRes.UserId || -1;
        if (validateRes.Settings?.allowNSFW) {
          setAllowNSFW(validateRes.Settings.allowNSFW);
        }
      } catch {
        Cookies.remove('token');
        myID = -1;
      }
    }

    try {
      setLoading(true);

      const { data: postsRes } = await axios.get(
        `/api/v1/posts?query=${searchParams.get('query')}&myID=${myID}&continuesFrom=${continuesFrom}&onlyFollow=false`
      );

      setData((prevData) => ({
        posts: [...prevData.posts, ...postsRes.posts],
        users: { ...prevData.users, ...postsRes.users },
      }));

      if (postsRes.posts.length > 0) {
        setContinuesFrom(postsRes.posts[postsRes.posts.length - 1].PostID);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  }, [continuesFrom, hasMore, loading, searchParams]);

  useEffect(() => {
    setData({ posts: [], users: {} });
    setContinuesFrom(null);
    setHasMore(true);
    fetchPosts();
  }, [searchParams, fetchPosts]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 10 &&
        hasMore &&
        !loading
      ) {
        fetchPosts();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, loading, fetchPosts]);

  return (
    <React.Fragment>
      <GlobalStyle />
      <PageContainer>
        <Navigation />
        <StyledFeed>
          <PostList posts={data.posts} users={data.users} />
        </StyledFeed>
        <MobileNav />
        <SearchBar />
      </PageContainer>
    </React.Fragment>
  );
};

export default SearchPage;
