import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import PostList from './PostList';
import SearchBar from './SearchBar';
import Navigation from './Navigation';
import MobileNav from './MobileNav';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const StyledFeed = styled.div`
  width: 40%;
  margin: 0 auto !important;
  padding: 20px;
  background-color: #06060e;
  border-top: 1px solid orange;

  @media (min-width: 768px) {
    border-left: 1px solid orange;
    border-right: 1px solid orange;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const Feed = () => {
  const [data, setData] = useState({posts: [], users: {}});
  const [AllowNSFW, setAllowNSFW] = useState(false);
  const [continuesFrom, setContinuesFrom] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const token = Cookies.get('token');
    let myID = -1;

    if (token) {
      try {
        axios.get('/api/v1/validate', {
          headers: { 'Authorization': `Bearer ${token}` }
        }).then(res => {
          myID = res.status === 200 ? res.data.UserId : -1;
          if (res.data.Settings && res.data.Settings.allowNSFW) {
            setAllowNSFW(res.data.Settings.allowNSFW);
          }
        });
      } catch (error) {
        Cookies.remove('token');
        myID = -1;
      }
    }

    setLoading(true);
    axios.get('/api/v1/posts?myID=' + myID + '&continuesFrom=' + continuesFrom + '&onlyFollow=false')
    .then(res => {
      setData(res.data);

      if (res.data.posts.length > 0) {
        setContinuesFrom(res.data.posts[data.posts.length - 1].thisPostID || res.data.posts[data.posts.length - 1].PostID);
      }

      setHasMore(res.data.posts.length > 0);
      setLoading(false);
    })
  }, [continuesFrom]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 10 &&
        hasMore &&
        !loading
      ) {
        setContinuesFrom((prev) => (data.posts.length > 0 ? data.posts[data.posts.length - 1].PostID : prev));
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, loading, data.posts]);

  return (
    <React.Fragment>
      <GlobalStyle />
      <StyledFeed>
        {/* <PostBox AllowNSFW={AllowNSFW} isQuote={false} /> */}
        <PostList posts={data.posts} users={data.users} />
      </StyledFeed>
      <SearchBar />
    </React.Fragment>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;

  @media (max-width: 768px) {
    margin: 0px;
  }
`;

const Explore = () => {
  return (
    <Layout>
      <Navigation />
      <Feed />
      <MobileNav />
    </Layout>
  );
};

export default Explore;