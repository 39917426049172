import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import PostItem from './PostItem';
import PostBox from './PostBox';
import Navigation from './Navigation';
import MobileNav from './MobileNav';
import SearchBar from './SearchBar';

// Global styling for the page background
const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

// Styling for the entire feed (post thread container)
const StyledFeed = styled.div`
  width: 40%;
  margin: 0 auto;
  border: 1px solid orange;
  padding: 20px;
  background-color: #06060e;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10px;
  gap: 20px;
`;

// Component for rendering the SinglePost page
const SinglePost = () => {
  const { postId } = useParams(); // Get the postId from the URL
  const [postData, setPostData] = useState(null); // Store the response from the endpoint
  const [isPosting, setIsPosting] = useState(false);
  const [newPost, setNewPost] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const fileInput = useRef(null); // For file input reference

  // Fetch the single post data from the server
  useEffect(() => {
    console.log("Rendered SinglePost!");
    axios.get(`/api/v1/single_post?postId=${postId}&myID=${Cookies.get('myID') || -1}`)
      .then((res) => {
        setPostData(res.data); // Store the post and thread data
      })
      .catch((err) => {
        console.error('Error fetching single post data:', err);
      });
  }, [postId]);

  // Handle the file upload for images in replies
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileSizeLimit = 20 * 1024 * 1024; // 20 MB limit
      if (file.size <= fileSizeLimit) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const fileData = { data: reader.result, file, type: file.type };
          setSelectedImages((prev) => [...prev, fileData]);
        };
        reader.readAsDataURL(file);
      } else {
        alert(`File size must be less than ${fileSizeLimit / 1024 / 1024}MB.`);
      }
    }
  };

  // Handle submitting the reply
  const makePost = () => {
    const token = Cookies.get('token');
    const formData = new FormData();
    formData.append('Text', newPost);
    formData.append('ReplyTo', postId); // Attach the reply to the postId

    if (newPost === "" && selectedImages.length === 0) {
      alert("Post must have content");
      return;
    }

    setIsPosting(true);

    // Add images if any
    if (selectedImages.length === 0) {
      formData.append('Images', [null]);
    } else {
      selectedImages.forEach((image) => {
        formData.append('Files', image.file);
      });
    }

    axios({
      method: 'post',
      url: '/api/v1/send_post',
      data: formData,
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(() => {
        window.location.reload(); // Reload the page after posting
      })
      .catch((err) => console.log(err))
      .finally(() => setIsPosting(false));
  };

  const handleUploadClick = () => {
    fileInput.current.click(); // Trigger file upload dialog
  };

  if (!postData) {
    return null;
  }

  const { post, threadBefore, threadAfter, users } = postData; // Extract data from the API response

  console.log('Post:' + JSON.stringify(post));

  return (
    <React.Fragment>
      <GlobalStyle />
      <PageContainer>
        <Navigation />
        <StyledFeed>
          {/* Render parent posts (thread before) */}
          {threadBefore.map((parentPost) => parentPost.PostID !== post.PostID ? (
            <PostItem
              key={parentPost.PostID}
              post={parentPost}
              user={users[parentPost.PosterId]}
              liked={parentPost.iLiked}
              howled={parentPost.iHowled}
              likeCount={parentPost.LikeCount}
              handleLikeToggle={() => {}}
              expanded={true}
              handleExpand={() => {}}
              howlCount={parentPost.howlCount}
              quoteCount={parentPost.quoteCount}
            />
          ) : null)}

          {/* Render the single post */}
          <PostItem
            post={post}
            user={users[post.PosterId]}
            liked={post.iLiked}
            howled={post.iHowled}
            likeCount={post.LikeCount}
            handleLikeToggle={() => {}}
            expanded={true}
            handleExpand={() => {}}
            primary={true}
            howlCount={post.howlCount}
            quoteCount={post.quoteCount}
          />

          {/* todo: allownsfw */}
          <PostBox
            newPost={newPost}
            setNewPost={setNewPost}
            makePost={makePost}
            isPosting={isPosting}
            handleFileChange={handleFileChange}
            fileInput={fileInput}
            selectedImages={selectedImages}
            replyTo={post.PostID}
          />

          {/* Render replies (thread after) */}
          {threadAfter.map((childPost) => childPost.PostID !== post.PostID ? (
            <PostItem
              key={childPost.PostID}
              post={childPost}
              user={users[childPost.PosterId]}
              liked={childPost.iLiked}
              howled={childPost.iHowled}
              likeCount={childPost.LikeCount}
              handleLikeToggle={() => {}}
              expanded={false}
              handleExpand={() => {}}
              howlCount={childPost.howlCount}
              quoteCount={childPost.quoteCount}
            />
          ) : null)}
        </StyledFeed>
        <MobileNav />
        <SearchBar />
      </PageContainer>
    </React.Fragment>
  );
};

export default SinglePost;
