import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Navigation from './Navigation';
import MobileNav from './MobileNav';
import SearchBar from './SearchBar';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10px;
  gap: 20px;
`;

const StyledSettings = styled.div`
  width: 40%;
  margin: 0 auto !important;
  border: 1px solid orange;
  padding: 20px;
  background-color: #06060e;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
`;

const SettingsTitle = styled.h2`
  color: #fff;
  text-align: center;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
`;

const DescriptionText = styled.p`
  color: #fff;
`;

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  transition: .4s;
  border: 2px solid orange;
  border-radius: 34px; // make it fully rounded

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%; // make it a circle
  }

  ${ToggleInput}:checked + & {
    background-color: #6e4709;
  }

  ${ToggleInput}:checked + &:before {
    transform: translateX(24px);
  }

  ${ToggleInput}:focus + & {
    box-shadow: 0 0 1px #2196F3;
  }

  ${ToggleInput}:active + & {
    background-color: #000;
  }
`;

const Privacy = () => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <PageContainer>
        <Navigation />
        <StyledSettings>
            <SettingsTitle>
                Privacy Policy
            </SettingsTitle>
            <DescriptionText>
                Last Updated: December 5, 2024
            </DescriptionText>
            <DescriptionText>
                Your privacy is important to us. This Privacy Policy explains how YipYap (“we,” “us,” or “our”) collects, uses, discloses, and safeguards your personal information when you access or use YipYap and its associated websites, services, and applications (collectively, the “Platform”). By using the Platform, you consent to the practices described in this Privacy Policy. If you do not agree, please discontinue use immediately.
            </DescriptionText>
            <br />

            <SettingsTitle>
                1. Information We Collect
            </SettingsTitle>
            <DescriptionText>
            We collect various types of information to provide and improve our services, as well as to enhance your experience on the Platform. This includes:
            </DescriptionText>
            <DescriptionText>
                1.1 Account Information: When you register, we may collect your username, email address, password, display name, profile picture, and bio. Usernames must be alphanumeric.
            </DescriptionText>
            <DescriptionText>
                1.2 Content You Provide: We collect any text, images, videos, and other content you choose to post on the Platform. This may include posts, comments, likes, howls, replies, quotes, and any metadata associated with this content.
            </DescriptionText>
            <DescriptionText>
                1.3 Usage Data: We collect information about how you interact with the Platform, such as the features you use, the pages or posts you view, and the frequency and duration of your visits. This may include your device type, operating system, browser type, IP address, and timestamps of your activities.
            </DescriptionText>
            <DescriptionText>
                1.4 Cookies and Similar Technologies: We use cookies, web beacons, and similar technologies to remember your preferences, maintain your login sessions, and analyze site traffic. You can control cookie preferences through your browser settings, but disabling cookies may affect some Platform features.
            </DescriptionText>
            <DescriptionText>
                1.5 Communications and Support: If you contact us or participate in surveys, promotions, or newsletters, we may collect the information you provide, such as your name, email address, and the content of your messages.
            </DescriptionText>
            <br />

            <SettingsTitle>
                2. How We Use Your Information
            </SettingsTitle>
            <DescriptionText>
                We use the information we collect for various purposes, including to:
            </DescriptionText>
            <DescriptionText>
                2.1 Operate and Maintain the Platform: Provide the core functionalities of YipYap, including account creation, content posting, and content discovery.
            </DescriptionText>
            <DescriptionText>
                2.2 Personalize Your Experience: Show you content, recommendations, and features that are relevant to your interests and activities on the Platform.
            </DescriptionText>
            <DescriptionText>
                2.3 Communicate with You: Send you service-related announcements, respond to your inquiries, and provide customer support. If you opt-in to receive our newsletter, we’ll send periodic updates. You can unsubscribe at any time.
            </DescriptionText>
            <DescriptionText>
                2.4 Improve and Develop Our Services: Analyze usage data to understand trends, fix bugs, enhance security, and test new features.
            </DescriptionText>
            <DescriptionText>
                2.5 Legal and Security Purposes: Detect, investigate, and prevent fraudulent, unauthorized, or illegal activities. Enforce our Terms of Service and comply with legal obligations.
            </DescriptionText>
            <br />

            <SettingsTitle>
                3. How We Share Your Information
            </SettingsTitle>
            <DescriptionText>
                We will not sell or rent your personal information to third parties. We may share information in the following circumstances:
            </DescriptionText>
            <DescriptionText>
                3.1 With Other Users: Any content you post publicly on YipYap (such as posts, comments, or profile details) will be visible to other users. Please consider the sensitivity of the information you post publicly.
            </DescriptionText>
            <DescriptionText>
                3.2 Service Providers and Vendors: We may share information with trusted third-party service providers who assist us in operating the Platform, performing analytics, hosting services, or providing customer support. These partners are contractually bound to protect your information and use it only for agreed-upon purposes.
            </DescriptionText>
            <DescriptionText>
                3.3 Legal Compliance and Protection: We may disclose information if required by law, regulation, or legal process, or in response to a lawful request from public authorities. We may also disclose information to protect our rights, property, and safety, or that of others.
            </DescriptionText>
            <DescriptionText>
                3.4 Business Transfers: In the event of a merger, acquisition, reorganization, or sale of assets, your information may be transferred to a successor entity. We will notify you of any changes in ownership or use of your personal information.
            </DescriptionText>
            <br />

            <SettingsTitle>
                4. Data Retention
            </SettingsTitle>
            <DescriptionText>
                We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. The duration may depend on the type of data, the nature of our relationship with you, and legal obligations.
            </DescriptionText>
            <br />

            <SettingsTitle>
                5. Your Choices and Rights
            </SettingsTitle>
            <DescriptionText>
                5.1 Access, Update, and Delete: You may access and update your profile information at any time through your account settings. You may also request the deletion of your account and personal data. Please note that certain data may be retained for legal, security, or backup purposes.
            </DescriptionText>
            <DescriptionText>
                5.2 Cookies and Analytics: You can manage cookie preferences and opt-out of certain analytics services by adjusting your browser settings or following the opt-out instructions provided by third-party analytics providers.
            </DescriptionText>
            <DescriptionText>
                5.3 Opting Out of Newsletters: If you have subscribed to our newsletter, you can opt out at any time by clicking the unsubscribe link in the email or adjusting your account settings.
            </DescriptionText>
            <br />

            <SettingsTitle>
                6. Security
            </SettingsTitle>
            <DescriptionText>
                We take reasonable measures to help protect your personal information from loss, theft, misuse, and unauthorized access or disclosure. However, no security method is entirely foolproof, and we cannot guarantee absolute security.
            </DescriptionText>
            <br />

            <SettingsTitle>
                7. Children's Privacy
            </SettingsTitle>
            <DescriptionText>
                YipYap is not intended for individuals under the age of 13. If we learn that we have collected personal information from a user under 13 without parental consent, we will take steps to delete such information promptly.
            </DescriptionText>
            <br />

            <SettingsTitle>
                8. International Data Transfers
            </SettingsTitle>
            <DescriptionText>
                Your information may be transferred to and maintained on servers located in other jurisdictions where privacy laws may not be as protective as those in your home country. By using the Platform, you consent to such transfers.
            </DescriptionText>
            <br />

            <SettingsTitle>
                9. Third-Party Links
            </SettingsTitle>
            <DescriptionText>
                YipYap may contain links to third-party websites or services. We are not responsible for the privacy practices or content of third parties. We encourage you to read their privacy policies before providing any personal information.
            </DescriptionText>
            <br />

            <SettingsTitle>
                10. Changes to This Privacy Policy
            </SettingsTitle>
            <DescriptionText>
                We may update this Privacy Policy from time to time. Changes will be effective upon posting. Your continued use of YipYap after changes are posted constitutes your acceptance of the revised Privacy Policy.
            </DescriptionText>
            <br />

            <SettingsTitle>
                11. Contact Us
            </SettingsTitle>
            <DescriptionText>
                If you have any questions, comments, or requests regarding this Privacy Policy or your personal information, please contact us at <b>contact@yipyap.io</b>.
            </DescriptionText>

        </StyledSettings>
        <MobileNav />
        <SearchBar />
      </PageContainer>
    </React.Fragment>
  );
}

export default Privacy;
