import React from 'react';
import styled from 'styled-components';

const FilterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 20px;
`;

const FilterButton = styled.button`
  background-color: ${({ selected }) => (selected ? 'orange' : 'transparent')};
  color: #fff;
  border: 1px solid orange;
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  width: 100%;

  &:hover {
    background-color: ${({ selected }) => (selected ? 'orange' : 'rgba(255, 255, 255, 0.1)')};
  }
`;

const RightFixedNav = styled.div`
  position: fixed;
  top: 340px;
  right: 5%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  background-color: #06060e;
  padding: 20px;
  border: 1px solid orange;
  border-radius: 10px;
  z-index: 10; // Ensure it stays on top of other content
  box-sizing: border-box;

  @media (max-width: 768px) {
    display: none; // Hide on smaller screens if needed
  }
`;



const FilterYap = ({
    setPhotosOnly,    photosOnly,     setNoPhotos,   noPhotos,
    setVideosOnly,    videosOnly,     setNoVideos,   noVideos,
    setHowlsOnly,     howlsOnly,      setNoHowls,    noHowls,
    setRepliesOnly,   setNoReplies,   repliesOnly,   noReplies
  }) => {
  return (
    <RightFixedNav>
    <h3 style={{ color: "white" }}>Filter Yaps By</h3>
    <FilterContainer>
      <FilterButton
        selected={photosOnly}
        onClick={() => {
          setPhotosOnly(!photosOnly);
          if (!photosOnly) setNoPhotos(false);
        }}
      >
        Photos Only
      </FilterButton>
      
      <FilterButton
        selected={noPhotos}
        onClick={() => {
          setNoPhotos(!noPhotos);
          if (!noPhotos) setPhotosOnly(false);
        }}
      >
        No Photos
      </FilterButton>

      <FilterButton
        selected={videosOnly}
        onClick={() => {
          setVideosOnly(!videosOnly);
          if (!videosOnly) setNoVideos(false);
        }}
      >
        Videos Only
      </FilterButton>
      
      <FilterButton
        selected={noVideos}
        onClick={() => {
          setNoVideos(!noVideos);
          if (!noVideos) setVideosOnly(false);
        }}
      >
        No Videos
      </FilterButton>

      <FilterButton
        selected={howlsOnly}
        onClick={() => {
          setHowlsOnly(!howlsOnly);
          if (!howlsOnly) setNoHowls(false);
        }}
      >
        Howls Only
      </FilterButton>

      <FilterButton
        selected={noHowls}
        onClick={() => {
          setNoHowls(!noHowls);
          if (!noHowls) setHowlsOnly(false);
        }}
      >
        No Howls
      </FilterButton>



      <FilterButton
        selected={repliesOnly}
        onClick={() => {
          setRepliesOnly(!repliesOnly);
          if (!repliesOnly) setNoReplies(false);
        }}
      >
        Replies Only
      </FilterButton>
      
      <FilterButton
        selected={noReplies}
        onClick={() => {
          setNoReplies(!noReplies);
          if (!noReplies) setRepliesOnly(false);
        }}
      >
        No Replies
      </FilterButton>
    </FilterContainer>
  </RightFixedNav>
  );
};

export default FilterYap;
