import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Navigation from './Navigation';
import MobileNav from './MobileNav';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const StyledFeed = styled.div`
  width: 40%;
  margin: 0 auto !important;
  padding: 20px;
  background-color: #06060e;
  border-top: 1px solid orange;

  @media (min-width: 768px) {
    border-left: 1px solid orange;
    border-right: 1px solid orange;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const PostContainer = styled.div`
  border: 1px solid orange;
  margin-bottom: 10px;
  padding: 15px;
  color: white;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;

  button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .approve {
    background-color: green;
    color: white;
  }

  .disapprove {
    background-color: red;
    color: white;
  }
`;

const AdminFeed = () => {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get('token');

    if (!token) {
      navigate('/');
      return;
    }

    axios
      .get('/api/v1/validate', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.UserId !== 0) {
          navigate('/');
        }
      })
      .catch(() => {
        navigate('/');
      });
  }, [navigate]);

  useEffect(() => {
    const token = Cookies.get('token');

    if (!token) {
      navigate('/');
      return;
    }
    axios
      .get('/api/v1/get_unfriendly', {
        headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setPosts(res.data.posts || []);
      })
      .catch((err) => {
        console.error('Error fetching unreviewed posts:', err);
      });
  }, []);

  const handleApprove = (postId) => {
    const token = Cookies.get('token');

    if (!token) {
      navigate('/');
      return;
    }
    axios
      .post('/api/v1/approve', { 
        headers: { Authorization: `Bearer ${token}` }, postId })
      .then(() => {
        setPosts((prevPosts) => prevPosts.filter((post) => post.PostID !== postId));
      })
      .catch((err) => console.error('Error approving post:', err));
  };

  const handleDisapprove = (postId) => {
    const token = Cookies.get('token');

    if (!token) {
      navigate('/');
      return;
    }
    axios
      .post('/api/v1/disapprove', {
        headers: { Authorization: `Bearer ${token}` }, postId })
      .then(() => {
        setPosts((prevPosts) => prevPosts.filter((post) => post.PostID !== postId));
      })
      .catch((err) => console.error('Error disapproving post:', err));
  };

  return (
    <React.Fragment>
      <GlobalStyle />
      <StyledFeed>
        {posts.map((post) => (
          <PostContainer key={post.PostID}>
            <p>{post.Text}</p>
            <ActionButtons>
              <button className="approve" onClick={() => handleApprove(post.PostID)}>
                ✓ Approve
              </button>
              <button className="disapprove" onClick={() => handleDisapprove(post.PostID)}>
                ✗ Disapprove
              </button>
            </ActionButtons>
          </PostContainer>
        ))}
        {posts.length === 0 && <p style={{ color: 'white', textAlign: 'center' }}>No unreviewed posts.</p>}
      </StyledFeed>
    </React.Fragment>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;

  @media (max-width: 768px) {
    margin: 0px;
  }
`;

const ExploreCheck = () => {
  return (
    <Layout>
      <Navigation />
      <AdminFeed />
      <MobileNav />
    </Layout>
  );
};

export default ExploreCheck;
