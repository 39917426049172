import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import PostList from './PostList';
import PostBox from './PostBox';
import SearchBar from './SearchBar';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const StyledFeed = styled.div`
  width: 40%;
  margin: 0 auto !important;
  padding: 20px;
  background-color: #06060e;
  border-top: 1px solid #332f29;

  @media (min-width: 768px) {
    border-left: 1px solid #332f29;
    border-right: 1px solid #332f29;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const Feed = () => {
  const [data, setData] = useState({ posts: [], users: {} });
  const [AllowNSFW, setAllowNSFW] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch feed data from the Bluesky API
        const response = await axios.get('/api/v1/bsky/feed');
        const feedData = response.data;

        const posts = feedData.timeline;

        // Map user IDs to user data for quick access
        const userPromises = posts.map(async (post) => {
          const userResponse = await axios.get(`/api/v1/bsky/user/${post.author}`);
          return {
            [post.author]: {
              UserId: post.author,
              DisplayName: userResponse.data.displayName,
              Username: userResponse.data.handle,
              ProfilePictureURL: userResponse.data.profilePicture,
            },
          };
        });

        const usersArray = await Promise.all(userPromises);
        const users = usersArray.reduce((acc, userObj) => ({ ...acc, ...userObj }), {});

        // Update state with posts and user data
        setData({
          posts: posts,
          users: users,
        });

        setLoading(false);
      } catch (error) {
        console.error('Error fetching feed data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <GlobalStyle />
      <StyledFeed>
        <PostBox AllowNSFW={AllowNSFW} isQuote={false} />
        {loading ? (
          <p>Loading...</p>
        ) : (
          <PostList posts={data.posts} users={data.users} />
        )}
      </StyledFeed>
      <SearchBar />
    </React.Fragment>
  );
};

export default Feed;
