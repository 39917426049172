import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

// Top bar styles (visible only on mobile)
const TopBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: #06060e;
  display: flex;
  align-items: center;
  padding: 0 15px;
  z-index: 1000;
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

// Button that opens the sliding menu
const MenuButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
`;

// Sliding menu styles
const SlideMenu = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? '0' : '-250px')};
  width: 250px;
  height: 100vh;
  background-color: #06060e;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: left 0.3s ease;
  z-index: 999;
`;

// Menu items container
const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  padding-top: 50px;
`;

// Each menu item (button)
const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
  background-color: #1a1a1a;
  transition: background-color 0.2s;

  &:hover {
    background-color: #333;
  }
`;

// Menu item icon
const MenuIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

// Bottom navigation styles for mobile
const BottomNav = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: #06060e;
  justify-content: space-around;
  align-items: center;
  display: none;
  z-index: 1000000000;

  @media (max-width: 768px) {
    display: flex;
  }
`;

// Navigation icon styles for bottom nav
const NavIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 998;
`;

const MobileNav = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const token = Cookies.get('token');
  const username = Cookies.get('username');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Use an effect to push the page down when the top bar is visible
  useEffect(() => {
    const updatePagePadding = () => {
      if (window.innerWidth <= 768) {
        // Push the page content down by 50px (height of the bar)
        document.body.style.paddingTop = '50px';
      } else {
        // Remove padding on larger screens
        document.body.style.paddingTop = '0';
      }
    };

    // Update padding initially and when the window is resized
    updatePagePadding();
    window.addEventListener('resize', updatePagePadding);

    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', updatePagePadding);
  }, []);

  return (
    <>
      {/* Top Bar (visible only on mobile) */}
      <TopBar>
        <MenuButton onClick={toggleMenu}>
          <NavIcon src={'https://api.yipyap.io/resource/burger.png'} alt="Menu" />
        </MenuButton>
      </TopBar>

      {/* Overlay for closing the menu */}
      <Overlay isOpen={menuOpen} onClick={toggleMenu} />

      {/* Slide-in Menu */}
      <SlideMenu isOpen={menuOpen}>
        <MenuItems>
          <MenuItem to={!token ? '/login' : '/' + username}>
            <MenuIcon src={'https://api.yipyap.io/resource/person.png'} alt="Profile" />
            Profile
          </MenuItem>
          <MenuItem to={!token ? '/login' : '/settings'}>
            <MenuIcon src={'https://api.yipyap.io/resource/gear.png'} alt="Settings" />
            Settings
          </MenuItem>
        </MenuItems>
      </SlideMenu>

      {/* Bottom Navigation */}
      <BottomNav>
        <Link to="/">
          <NavIcon src={'https://api.yipyap.io/resource/home.png'} alt="Home" />
        </Link>
        <Link to="/explore">
          <NavIcon src={'https://api.yipyap.io/resource/search.png'} alt="Explore" />
        </Link>
        <Link to={!token ? '/login' : '/notifications'}>
          <NavIcon src={'https://api.yipyap.io/resource/bell.png'} alt="Notifications" />
        </Link>
      </BottomNav>
    </>
  );
};

export default MobileNav;