import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import { useEffect, useState, useRef } from 'react';
import FullscreenImageViewer from './FullscreenImageViews';
import VideoComponent from './CustomVideoPlayer';
import PostBox from './PostBox';

const DisplayName = styled(Link)`
  font-weight: bold;
  margin-right: 5px;
  color: white;
  text-decoration: none;
`;

const PostText = styled.p`
  margin: 0px;
  flex: 1;
  white-space: pre-wrap;
`;

const PostTime = styled.span`
  font-size: 0.8em;
  color: grey;
`;

const PostTimeLink = styled.a`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: #332f29;
  margin: 0px auto;

  @media (min-width: 768px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PrimaryCountSection = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  color: white;
`;


const Post = styled.div`
  position: relative;
  background-color: #06060e;
  display: flex;
  padding: 10px;
  color: #FFF;
  padding-left: 0;
  z-index: ${({ isMenuOpen }) => (isMenuOpen ? 2000 : 1)}; // Increase z-index when menu is open

  &:not(:first-child)::before {
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    right: -20px;
  }
`;


const UserName = styled.span`
  margin: 0;
  color: #aaa;
  margin-right: 5px;
`;

const ProfilePicture = styled.img`
width: 40px;
height: 40px;
min-width: 40px;
min-height: 40px;
border-radius: 50%;
object-fit: cover;
overflow-clip-margin: unset;
object-position: center;
`;

const HeartImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 3px;
  cursor: pointer;
`;

const LikeCount = styled.span`
  font-size: 0.8em;
  color: white;
  display: inline-block;
`;

const LikeSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;

const ShowMoreButton = styled.span`
  display: block;
  color: orange;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ReplyImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 3px;
  cursor: pointer;
`;

const CopyImage = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
`;

const RepliesSection = styled.div`
  display: flex;
  align-items: center;
`;

const LikeSectionInner = styled.div`
  display: flex;
  align-items: center;
`;

const CopyUrlSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;


const processLineText = (line) => {
    const words = line.split(/(\s+)/);
  
    return words.map((word, index) => {
      if (word.startsWith('@')) {
        const username = word.substring(1);
        return (
          <a key={index} href={`https://yipyap.io/${username}`} style={{ color: 'orange', textDecoration: 'none' }}>
            {word}
          </a>
        );
      }
  
      if (word.startsWith('#')) {
        const hash = word.substring(1);
  
        const isNumeric = /^[0-9]+$/.test(hash);
  
        if (!isNumeric) {
          return (
            <Link key={index} to={`/search?query=${hash}`} style={{ color: 'orange', textDecoration: 'none' }}>
              {word}
            </Link>
          );
        }
      }
  
      return word;
    });
  };

const processPostText = (text) => {
  const lines = text.split(/\n/);

  return lines.map((line, lineIndex) => {
    let processedLine = line;

    if (processedLine.startsWith('\\# ')) {
      processedLine = processedLine.replace(/^\\#/, '# ');
    } else if (processedLine.startsWith('\\## ')) {
      processedLine = processedLine.replace(/^\\##/, '## ');
    } else if (processedLine.startsWith('\\### ')) {
      processedLine = processedLine.replace(/^\\###/, '### ');
    } 

    else if (processedLine.startsWith('### ')) {
      return <h3 style={{margin: '0'}} key={lineIndex}>{processLineText(processedLine.slice(4))}</h3>;
    } else if (processedLine.startsWith('## ')) {
      return <h2 style={{margin: '0'}} key={lineIndex}>{processLineText(processedLine.slice(3))}</h2>;
    } else if (processedLine.startsWith('# ')) {
      return <h1 style={{margin: '0'}} key={lineIndex}>{processLineText(processedLine.slice(2))}</h1>;
    }

    return <p style={{margin: '0'}} key={lineIndex}>{processLineText(processedLine)}</p>;
  });
};

function timeSince(date) {
    const seconds = Math.floor((new Date() - date) / 1000);
    let interval = Math.floor(seconds / 31536000);
  
    interval = Math.floor(seconds / 86400);
    if (interval >= 1)
        return format(date, 'MMMM do, yyyy');
    interval = Math.floor(seconds / 3600);
    if (interval >= 1)
        return interval + "h ago";
    interval = Math.floor(seconds / 60);
    if (interval >= 1)
        return interval + "m ago";
  
    return Math.floor(seconds) + "s ago";
  }

function PostItem({
        post,
        user,
        liked,
        howled,
        likeCount,
        handleLikeToggle,
        expanded,
        handleExpand,
        primary = false,
        showVerticalLine,

        howlCount,
        quoteCount,
      }) {

    const postRef = useRef(null);
    const [showButton, setShowButton] = useState(false);
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [showHowlMenu, setShowHowlMenu] = useState(false);
    const [showQuoteBox, setShowQuoteBox] = useState(false);
    const quoteBoxRef = useRef(null);
    
    const textRef = useRef(null);
    const [shouldFadeOutText, setShouldFadeOutText] = useState(false);
    
    useEffect(() => {
      if (textRef.current) {
        const textHeight = textRef.current.offsetHeight;
        const viewportHeight = window.innerHeight;
  
        if (textHeight > viewportHeight * 0.3) {
          setShouldFadeOutText(true);
          setShowButton(true); // Show "Show full Yap" button
        } else {
          setShouldFadeOutText(false);
          setShowButton(false); // No need for the button
        }
      }
    }, [expanded, post.Text]);

    useEffect(() => {
      if (expanded) {
        const handleScroll = () => {
          if (postRef.current) {
            const postBottom = postRef.current.getBoundingClientRect().bottom;
            const viewportHeight = window.innerHeight;
            setShowCloseButton(postBottom > viewportHeight);
          }
        };
        window.addEventListener('scroll', handleScroll);
  
        return () => window.removeEventListener('scroll', handleScroll);
      }
    }, [expanded]);

    const displayNumber = (num) => {
      if (!num || isNaN(num))
        return '0';
      if (num === 0)
        return '0';
      else
        return num.toString();
    }

    const handleHowlClick = (postId) => {
      const token = Cookies.get('token');
      const formData = new FormData();
      formData.append('Text', `<Howl ${postId}>`);
      formData.append('Images', [null]);
  
      formData.append('NSFW', false);
      formData.append('Tags', []);
  
      axios({
        method: 'post',
        url: '/api/v1/send_post',
        data: formData,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .catch(err => console.log(err));
    }

    const handleQuoteClick = () => {
      setShowHowlMenu(false);
      setShowQuoteBox(true);
    }

    const handleBackgroundClick = () => {
      setShowQuoteBox(false);
    }

    useEffect(() => {
      if (showQuoteBox && quoteBoxRef.current) {
        quoteBoxRef.current.focus();
      }
    }, [showQuoteBox]);

    const numImages = post.Images.length;
    const numColsRows = Math.ceil(Math.sqrt(numImages));

    const Grid = styled.div`
      display: grid;
      grid-template-columns: repeat(${numColsRows}, 1fr);
      grid-gap: 5px;
      max-width: 100%;
      max-height: 100%;
      max-width: 500px;
      margin-top: 5px;
    `;

    const ImageContainer = styled.div`
      width: 100%;
      height: 0;
      padding-bottom: 100%; // This ensures the aspect ratio of the container is kept square
      overflow: hidden;
      position: relative;
    `;

    const VideoContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
    width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
    margin: 0 auto;
    border-radius: 20px;
`;

const PostContent = styled.div`
  margin-left: 2px;
  width: 100%;
`;

const PostInside = styled.div`
  position: relative;
`;

const PostTextWrapper = styled.div`
  max-height: ${({ expanded, shouldFadeOut }) =>
    expanded || !shouldFadeOut ? 'none' : '30vh'};
  overflow: hidden;
  mask-image: ${({ expanded, shouldFadeOut }) =>
    expanded || !shouldFadeOut
      ? 'none'
      : 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 75%, transparent 100%)'};
  transition: max-height 0.3s ease, mask-image 0.3s ease;
`;

const HowlsSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
`;

const HowlMenu = styled.div`
  position: absolute;
  top: 25px;
  left: 0;
  width: 120px;
  background-color: #06060e;
  border: 1px solid #332f29;
  border-radius: 5px;
  z-index: 1000;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const HowlMenuItem = styled.div`
  padding: 10px;
  color: white;
  cursor: pointer;
  border-bottom: 1px solid #332f29;

  &:hover {
    background-color: #333;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const PostBoxWrapper = styled.div`
  width: 80%;
  background-color: #111;
  color: white;
  padding: 20px;
  border-radius: 5px;
`;

const HowlIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 3px;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow-clip-margin: unset;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4%;
`;

const HowlMessage = styled.p`
  color: grey;
  font-size: 0.9em;
  margin-bottom: 0;
  margin-top: 0;
`;

const PostContainer = styled.div`
display: flex;
`;

const LeftColumn = styled.div`
position: relative;
width: 50px;
display: flex;
flex-direction: column;
align-items: center;
top: 10px;
`;

const RightColumn = styled.div`
flex: 1;
position: relative;
`;

const VerticalLine = styled.div`
position: absolute;
top: 40px; // Adjust this to match the height of the profile picture
bottom: 0;
left: 50%;
transform: translateX(-50%);
width: 2px;
background-color: grey;
`;

const QuotedPostContainer = styled(Link)`
border: 1px solid grey;
border-radius: 10px;
padding: 10px;
margin-top: 10px;
display: flex;
text-decoration: none;
color: white;
max-height: 10vh;
overflow: hidden;
position: relative;
`;

const QuotedPostTextWrapper = styled.div`
flex: 1;
margin-left: 10px;
max-height: 10vh;
overflow: hidden;
position: relative;
`;

const QuotedPostDisplayName = styled(Link)`
font-weight: bold;
color: white;
margin-right: 5px;
text-decoration: none;
`;

const QuotedPostUserName = styled.span`
color: #aaa;
`;

const QuotedPostTextFadeOut = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7));
`;

const QuoteBoxContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const CloseButton = styled.button`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: orange;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  z-index: 1001;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const QuotedMediaGrid = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  max-height: 100px; /* Adjust as needed */
  overflow: hidden;
`;

const QuotedMediaPreview = styled.div`
  flex: 0 0 50px; /* Thumbnail size */
  height: 50px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
`;

const QuotedMediaImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const QuotedMediaVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const QuotedPostContainerWithMedia = styled(QuotedPostContainer)`
  display: flex;
  flex-direction: column;
`;

const DotsMenuIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const Menu = styled.div`
  position: absolute;
  top: 30px;
  right: 10px;
  background: #06060e;
  border: 1px solid #332f29;
  border-radius: 5px;
  z-index: 1000;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const MenuItem = styled.div`
  padding: 10px;
  color: white;
  cursor: pointer;
  border-bottom: 1px solid #332f29;

  &:hover {
    background-color: #333;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const Modal = styled.div`
  background: #111;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
`;

const ModalTitle = styled.h3`
  color: white;
  margin-bottom: 20px;
`;

const ModalButton = styled.button`
  background-color: ${({ primary }) => (primary ? 'red' : 'grey')};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 10px;

  &:hover {
    background-color: ${({ primary }) => (primary ? '#ff4d4d' : '#555')};
  }
`;

    const [isViewerOpen, setViewerOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [viewerImages, setViewerImages] = useState([]);

    const loggedInUser = Cookies.get('username');
    const [isMenuVisible, setMenuVisible] = useState(false);
    const [isModalVisible, setModalVisible] = useState(false);

    const openImageViewer = (images, index) => {
      setViewerImages(images);
      setCurrentImageIndex(index);
      setViewerOpen(true);
    };

    const closeImageViewer = () => {
      setViewerOpen(false);
    };


    const handleCopy = ( username, postId ) => {
      const postUrl = `https://yipyap.io/${username}/${postId}`;

      navigator.clipboard.writeText(postUrl).then(() => {
        console.log("Copied Yap URL");
      });
    };

    const handleDeletePost = () => {
      axios
        .post('/api/v1/delete_post', { postId: post.PostID }, {
          headers: { Authorization: `Bearer ${Cookies.get('token')}` },
        })
        .then(() => {
          setModalVisible(false);
          window.location.reload(); // Refresh to reflect the changes
        })
        .catch((error) => {
          console.error('Error deleting post:', error);
          alert('Failed to delete post.');
        });
    };

    const toggleMenu = () => setMenuVisible(!isMenuVisible);
  
    return (
      <div>
        {primary && <Divider />}
        <PostContainer isMenuOpen={showHowlMenu}>
          <LeftColumn>
            <ProfilePicture src={user.ProfilePictureURL} alt={user.Username}/>
            {showVerticalLine && <VerticalLine />}
          </LeftColumn>
          <RightColumn>
            {user.Username === loggedInUser && (
            <DotsMenuIcon
              src="https://api.yipyap.io/resource/dots.png"
              alt="Menu"
              onClick={toggleMenu}
            />
          )}
          <Menu visible={isMenuVisible}>
            <MenuItem onClick={() => { setMenuVisible(false); setModalVisible(true); }}>
              Delete
            </MenuItem>
          </Menu>
            <Post
              // onClick={handlePostClick}
              ref={postRef}
              isMenuOpen={showHowlMenu}
              >
              {/* onClick={onClick} */}

              {showQuoteBox && (
                <QuoteBoxContainer visible={showQuoteBox} onClick={handleBackgroundClick}>
                  <PostBoxWrapper onClick={(e) => e.stopPropagation()}>
                    <PostBox style={{'z-index': 10000}} AllowNSFW={true} Quote={post.PostID} isQuote={true} />
                    {/* todo: fix AllowNSFW (check user settings) */}
                  </PostBoxWrapper>
                </QuoteBoxContainer>
              )}
              {/* <ProfilePicture src={user.ProfilePictureURL} alt={user.Username}/> */}
              <PostContent>
              {post.reposterID && post.reposterName && post.reposterUsername && (
                  <HowlMessage>
                    <Link to={`/${post.reposterUsername}`} style={{ color: 'orange', textDecoration: 'none' }}>
                      {post.reposterName} howled
                    </Link>
                  </HowlMessage>
                )}
                <DisplayName to={`/${user.Username}`}>{user.DisplayName}</DisplayName>
                <UserName>@{user.Username}</UserName>
                <PostTimeLink href={`/${user.Username}/${post.PostID}`}>
                  <PostTime>{timeSince(new Date(post.PostTime * 1000))}</PostTime>
                </PostTimeLink>

                <PostInside style={{marginBottom: (primary ? '15px' : '0')}}>
                  <PostTextWrapper ref={textRef} expanded={expanded} shouldFadeOut={shouldFadeOutText}>
                    <PostText>{processPostText(post.Text)}</PostText>
                  </PostTextWrapper>
                  {post.Images && post.Images.length > 0 && (
                    <Grid>
                      {post.Images.slice(0, 4).map((mediaSrc, index) => {
                        const isVideo = mediaSrc.endsWith('.mp4') || mediaSrc.endsWith('.mov');
                        
                        return isVideo ? (
                          <VideoContainer key={index}>
                            <VideoComponent src={`https://api.yipyap.io/image/${mediaSrc}`} />
                          </VideoContainer>
                        ) : (
                          <ImageContainer key={index} onClick={() => openImageViewer(index)}>
                            <Image src={`https://api.yipyap.io/image/${mediaSrc}`} alt="" />
                          </ImageContainer>
                        );
                      })}
                    </Grid>
                  )}
                </PostInside>
                {isViewerOpen && (
                  <FullscreenImageViewer
                    images={post.Images.map(imgSrc => `https://api.yipyap.io/image/${imgSrc}`)}
                    currentIndex={currentImageIndex}
                    onClose={() => setViewerOpen(false)}
                  />
                )}
                
                {!expanded && showButton && (
                  <ShowMoreButton onClick={handleExpand}>Show full Yap</ShowMoreButton>
                )}

                {expanded && showCloseButton && (
                  <CloseButton visible={showCloseButton} onClick={handleExpand}>
                    Condense Yap
                  </CloseButton>
                )}
                {post.quotedPost && (
                  <QuotedPostContainerWithMedia
                    to={`/${post.quotedPoster.Username}/${post.quotedPost.PostID}`}
                  >
                    <div style={{ flex: 1 }}>
                      <QuotedPostDisplayName to={`/${post.quotedPoster.Username}`}>
                        {post.quotedPoster.DisplayName}
                      </QuotedPostDisplayName>
                      <QuotedPostUserName>@{post.quotedPoster.Username}</QuotedPostUserName>
                      <PostText>{post.quotedPost.Text}</PostText>
                    </div>
                    {post.quotedPost.Images && post.quotedPost.Images.length > 0 && (
                      <QuotedMediaGrid>
                        {post.quotedPost.Images.map((mediaSrc, index) => {
                          const isVideo = mediaSrc.endsWith('.mp4') || mediaSrc.endsWith('.mov');
                          return (
                              <QuotedMediaPreview
                                key={index}
                                onClick={(e) => {
                                  e.preventDefault();
                                  openImageViewer(
                                    post.quotedPost.Images.map(
                                      (src) => `https://api.yipyap.io/image/${src}`
                                    ),
                                    index
                                  );
                                }}
                              >
                              <QuotedMediaImage
                                src={`https://api.yipyap.io/image/${mediaSrc}`}
                                alt={`Quoted Media ${index + 1}`}
                              />
                            </QuotedMediaPreview>
                          );
                        })}
                      </QuotedMediaGrid>
                    )}
                  </QuotedPostContainerWithMedia>
                )}
                {primary && (
                  <>
                  <Divider />
                  <PrimaryCountSection>
                  <span>{displayNumber(post.replies)} Replies</span>
                  <a style={{color: 'white', textDecoration: 'none' }} href={`/quotes/${post.PostID}`}>{displayNumber(quoteCount)} Quotes</a>
                  <a style={{color: 'white', textDecoration: 'none' }} href={`/${user.Username}/${post.PostID}/howls`}>{displayNumber(howlCount)} Howls</a>
                  <a style={{color: 'white', textDecoration: 'none' }} href={`/${user.Username}/${post.PostID}/likes`}>{displayNumber(likeCount)} Likes</a>
                  </PrimaryCountSection>
                  <Divider />
                </>
                )}
                <LikeSection>
                  <RepliesSection>
                    <ReplyImage src={"https://api.yipyap.io/resource/quotes.png"} alt="Replies" />
                    <LikeCount>{displayNumber(post.replies)}</LikeCount>
                  </RepliesSection>
                  <HowlsSection onClick={() => setShowHowlMenu(!showHowlMenu)}>
                    <HowlIcon src={howled ? "https://api.yipyap.io/resource/howl-filled.png" : "https://api.yipyap.io/resource/howl.png"} alt="Howls" />
                    {!primary && <LikeCount>{displayNumber(howlCount + quoteCount)}</LikeCount>}
                    <HowlMenu visible={showHowlMenu}>
                      <HowlMenuItem onClick={() => handleHowlClick(post.PostID)}>Howl</HowlMenuItem>
                      <HowlMenuItem onClick={handleQuoteClick}>Quote</HowlMenuItem>
                    </HowlMenu>
                  </HowlsSection>

                  <LikeSectionInner>
                    <HeartImage 
                      src={liked ? "https://api.yipyap.io/resource/heart-filled.png" : "https://api.yipyap.io/resource/heart.png"} 
                      alt="Heart" 
                      onClick={() => handleLikeToggle(post.PostID)}
                    />
                    {!primary && <LikeCount>{likeCount}</LikeCount>}
                  </LikeSectionInner>

                  <CopyUrlSection onClick={() => handleCopy(user.Username, post.PostID)}>
                    <CopyImage src={"https://api.yipyap.io/resource/share.png"} alt="Copy URL" />
                  </CopyUrlSection>
                </LikeSection>
              </PostContent>
            </Post>
            <ModalOverlay visible={isModalVisible}>
              <Modal>
                <ModalTitle>Delete this post?</ModalTitle>
                <div>
                  <ModalButton onClick={handleDeletePost} primary>
                    Yes, delete
                  </ModalButton>
                  <ModalButton onClick={() => setModalVisible(false)}>
                    No
                  </ModalButton>
                </div>
              </Modal>
            </ModalOverlay>
          </RightColumn>
        </PostContainer>
        {isViewerOpen && (
          <FullscreenImageViewer
            images={viewerImages}
            currentIndex={currentImageIndex}
            onClose={closeImageViewer}
          />
        )}
      </div>
    );
  }

  export default PostItem;