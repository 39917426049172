import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import axios from 'axios';
import Cookies from 'js-cookie';
import PostList from './PostList';
import SearchBar from './SearchBar';
import Navigation from './Navigation';
import MobileNav from './MobileNav';
import { useParams } from 'react-router-dom';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #06060e;
  }
`;

const StyledFeed = styled.div`
  width: 40%;
  margin: 0 auto !important;
  padding: 20px;
  background-color: #06060e;
  border-top: 1px solid orange;

  @media (min-width: 768px) {
    border-left: 1px solid orange;
    border-right: 1px solid orange;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const Feed = () => {
  const [data, setData] = useState({ posts: [], users: {} });
  const [AllowNSFW, setAllowNSFW] = useState(false);
  const [continuesFrom, setContinuesFrom] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const { postId } = useParams();

  const fetchQuotes = async () => {
    if (loading || !hasMore) return;

    const token = Cookies.get('token');
    let myID = -1;

    if (token) {
      try {
        const { data: validateRes } = await axios.get('/api/v1/validate', {
          headers: { Authorization: `Bearer ${token}` },
        });
        myID = validateRes.UserId || -1;
        setAllowNSFW(validateRes.Settings?.allowNSFW || false);
      } catch {
        myID = -1;
        Cookies.remove('token');
      }
    }

    try {
      setLoading(true);

      const { data: postsRes } = await axios.get(
        `/api/v1/posts?myID=${myID}&quoting=${postId}&continuesFrom=${continuesFrom || ''}`
      );

      setData((prevData) => ({
        posts: [...prevData.posts, ...postsRes.posts],
        users: { ...prevData.users, ...postsRes.users },
      }));

      if (postsRes.posts.length > 0) {
        setContinuesFrom(postsRes.posts[postsRes.posts.length - 1].PostID);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching quotes:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setData({ posts: [], users: {} });
    setContinuesFrom(null);
    setHasMore(true);
    fetchQuotes();
  }, [postId]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 10 &&
        hasMore &&
        !loading
      ) {
        fetchQuotes();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, loading]);

  return (
    <React.Fragment>
      <GlobalStyle />
      <StyledFeed>
        <PostList posts={data.posts} users={data.users} />
      </StyledFeed>
      <SearchBar />
    </React.Fragment>
  );
};

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;

  @media (max-width: 768px) {
    margin: 0px;
  }
`;

const Explore = () => {
  return (
    <Layout>
      <Navigation />
      <Feed />
      <MobileNav />
    </Layout>
  );
};

export default Explore;
